import { useCallback, useMemo } from 'react';
import { getIn, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { matchIsValidTel } from 'mui-tel-input';

import {  useUserSettings } from '../utils/UserSettings';
import { showMessage } from '../utils/showMessage';
// import { useAppDispatch } from '../utils/useAppDispatch';

export const phoneFieldName = 'phone';
export const mobilePhoneFieldName = 'mobilePhone';

export type UserProfileSettings = Record<
  'firstName' | 'lastName' | 'email' | 'phone' | 'mobilePhone',
  string
>;

interface IUseEditUserProfile {
  values: UserProfileSettings;
  handleChange: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(fieldOrEvent: T): T extends string ? (e: unknown) => void : void;
  };
  handleBlur: {
    (e: React.FocusEvent<unknown, Element>): void;
    <T = unknown>(fieldOrEvent: T): T extends string ? (e: unknown) => void : void;
  };
  firstNameError: string | false | undefined;
  lastNameError: string | false | undefined;
  phoneError: string | undefined;
  mobilePhoneError: string | undefined;
  isSaveDisabled: boolean;
  handleSave: () => void;
  isSubmitting: boolean;
  handlePhoneChange: (formattedValue?: string) => void;
  handleMobilePhoneChange: (formattedValue?: string) => void;
  isEditEnabled: boolean;
}

export const useEditUserProfile = (): IUseEditUserProfile => {
  
  
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userSettings } = useUserSettings();
  const { firstName, lastName, phone, mobilePhone } = userSettings;

  const formik = useFormik<UserProfileSettings>({
    initialValues: {
      firstName: firstName || 'Test',
      lastName: lastName || 'Carrier',
      email: localStorage.getItem("email") || '',
      phone: phone || '1234674',
      mobilePhone: mobilePhone || '45735782',
    },
    validate: (validationValues) => {
      const validationErrors = {} as Record<string, string>;

    //   if (!validationValues.firstName) {
    //     validationErrors.firstName = 'user.management.validation.error.valid-first-name';
    //   }

    //   if (!validationValues.lastName) {
    //     validationErrors.firstName = 'user.management.validation.error.valid-last-name';
    //   }

    //   if (validationValues.phone && !matchIsValidTel(validationValues.phone || '')) {
    //     validationErrors.phone = 'user.management.validation.error.valid-phone';
    //   }

    //   if (validationValues.mobilePhone && !matchIsValidTel(validationValues.mobilePhone || '')) {
    //     validationErrors.mobilePhone = 'user.management.validation.error.valid-phone';
    //   }

      return validationErrors;
    },

    onSubmit: async (values) => {
        const { error } = await new Promise<{ error: null | string }>((resolve) => {
            setTimeout(() => resolve({ error: null }), 1000);
          });
    //   if (error) {
    //     showError(dispatch, 'user.management.edit-profile.error');
    //   }
      // showMessage(dispatch, 'user.management.edit-profile.success');
      navigate(0);
    },
    validateOnMount: true,
  });

  const { values, touched, errors, handleChange, handleBlur, setFieldTouched, setFieldValue } =
    formik;

  const handlePhoneChange = useCallback(
    (formattedValue: string = '') => {
      setFieldTouched(phoneFieldName, true);
      setFieldValue(phoneFieldName, formattedValue);
    },
    [setFieldTouched, setFieldValue]
  );

  const handleMobilePhoneChange = useCallback(
    (formattedValue: string = '') => {
      setFieldTouched(mobilePhoneFieldName, true);
      setFieldValue(mobilePhoneFieldName, formattedValue);
    },
    [setFieldTouched, setFieldValue]
  );

  const handleSave = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const isEditEnabled = true;

  // const isEditEnabled = useMemo(() => !supportSessionId, [supportSessionId]);

  const firstNameError = touched.firstName && errors.firstName && 'errors.firstName';
  const lastNameError = touched.lastName && errors.lastName && 'errors.lastName';

  const phoneError =
    isEditEnabled && getIn(errors, phoneFieldName) && getIn(errors, phoneFieldName);

  const mobilePhoneError =
    isEditEnabled && getIn(errors, mobilePhoneFieldName) && getIn(errors, mobilePhoneFieldName);

  const isSaveDisabled =
    formik.isSubmitting ||
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.phone ||
    !!errors.mobilePhone;

  const { isSubmitting } = formik;

  return {
    values,
    handleChange,
    handleBlur,
    firstNameError,
    lastNameError,
    phoneError,
    mobilePhoneError,
    isSaveDisabled,
    handleSave,
    isSubmitting,
    handlePhoneChange,
    handleMobilePhoneChange,
    isEditEnabled,
  };
};
