import React from "react";
import { Box } from "@mui/material";
import ttiMetricImage from "../assets/tti-api-metrics.png";
import pushMetricImage from "../assets/push-api-metrics.png";
import containerMetricImage from "../assets/container-api-metrics.png";
import "../styles/OverViewPage.css";

const OverViewPage: React.FC = React.memo(() => {

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box>
        {[
          { image: ttiMetricImage, title: "Pull API Metrics" },
          { image: pushMetricImage, title: "Push API Metrics" },
          { image: containerMetricImage, title: "Container API Metrics" },
        ].map(({ image, title }, index) => (
          <Box key={index} mt={4}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.25rem",
                marginBottom: "8px",
                textAlign: "center",
              }}
            >
              {title}
            </p>
            <img
              src={image}
              alt={`Metric Image ${index + 1}`}
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </Box>
        ))}
      </Box>
    </div>
  );
});

export default OverViewPage;
