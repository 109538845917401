import React, {createContext, useState} from "react";

interface BusinessUnitContextValue {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

export const BusinessUnitContext = createContext<BusinessUnitContextValue>({
  selectedOption: "TTI",
  setSelectedOption: () => {},
});

export const BusinessUnitProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [selectedOption, setSelectedOption] = useState("TTI");

  return (
    <BusinessUnitContext.Provider value={{selectedOption, setSelectedOption}}>
      {children}
    </BusinessUnitContext.Provider>
  );
};
