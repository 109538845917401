import {
    createContext,
    useContext,
  } from 'react';

  export type UserSettings = {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    timezone?: string;
    phone?: string;
    mobilePhone?: string;
  };
  export const defaultUserSettings: UserSettings = {
    firstName: 'Dev',
    lastName: 'Admin',
    fullName: 'Dev Admin',
    
  };
  

  export interface UserSettingsContextInterface {
    userSettings: UserSettings;
    onUserSettingsChange: (key: string, value: unknown) => void;
    onBatchUserSettingsChange: (
      userSettings: any
    ) => Promise<any>;
    loading: boolean;
  }
  
export const UserSettingsContext = createContext<UserSettingsContextInterface>({
    userSettings: defaultUserSettings,
    onUserSettingsChange: () => {},
    onBatchUserSettingsChange: () => Promise.resolve({ success: true, error: null }),
    loading: false,
    // hasAccessToLocationData: false,
    // hasAccessToUserLevelAddressVisibility: false,
  });
  
  export const useUserSettings = (): UserSettingsContextInterface => {
    const context = useContext(UserSettingsContext);
  
    return context;
  };