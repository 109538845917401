import React, {useEffect, useState, useMemo, useCallback, useContext} from "react";
import {
  Box,
  InputBase,
  Divider,
  Typography,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import {Search} from "@mui/icons-material";
import styles from "../styles/CompanyManagement.module.css";
import TableComponent from "./Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfiniteScroll from "react-infinite-scroll-component";
import {LinearProgress} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {BusinessUnitContext} from "../context/BusinessUnitContext";

interface Company {
  name: string;
  contactRegion: string;
  contactCountry: string;
  companyType: string;
  contactName: string;
  parentId: string;
  lastModifiedBy: string;
  lastModified: string;
  assets: number;
  users: number;
}

interface User {
  fullName: string;
  email: string;
  phone: string;
  mobilePhone: string;
  name: string;
  groups: number;
  lastModified: string;
  apiKey: React.ReactNode;
}

const formatTimestamp = (timestamp: string | null) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Handle null or NaN input
  if (timestamp === null || isNaN(Date.parse(timestamp))) {
    return "-";
  }

  // Try parsing the timestamp as an ISO string
  let date = new Date(timestamp);
  if (!isNaN(date.getTime())) {
    const day = daysOfWeek[date.getDay()];
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    const formattedTime = `${date.getHours() % 12 || 12}:${String(date.getMinutes()).padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return `${day}, ${formattedDate} ${formattedTime}`;
  }

  // If the ISO string parsing fails, try parsing the timestamp as a custom string
  const parts = timestamp.split(" ");
  const dayName = parts[0];
  const month = parts[1];
  const day = parseInt(parts[2], 10);
  const year = parseInt(parts[3], 10);
  const time = parts[4].split(":");
  const hours = parseInt(time[0], 10);
  const minutes = parseInt(time[1], 10);
  const ampm = parts[5];

  const formattedDay = daysOfWeek.indexOf(dayName);
  const formattedDate = `${month} ${day}, ${year}`;
  const formattedTime = `${hours % 12 || 12}:${String(minutes).padStart(2, "0")} ${ampm}`;

  return `${daysOfWeek[formattedDay]}, ${formattedDate} ${formattedTime}`;
};

const CompanyManagement: React.FC = () => {
  const {selectedOption} = useContext(BusinessUnitContext);
  const [selectedTab, setSelectedTab] = useState("Companies");

  const [companies, setCompanies] = useState<Company[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [companieCount, setCompanieCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nextCompanyToken, setNextCompanyToken] = useState<string | null>(null);
  const [nextUserToken, setNextUserToken] = useState<string | null>(null);

  const companyTableHeader = useMemo(
    () => [
      {name: "Company Name", key: "name"},
      {name: "Region", key: "contactRegion"},
      {name: "Country", key: "country"},
      {name: "Company Type", key: "companyType"},
      {name: "Company Admin", key: "contactName"},
      {name: "Has Parent", key: "hasParent"},
      {name: "Last Modified By", key: "lastModifiedBy"},
      {name: "Last Modified On", key: "lastModified"},
      {name: "Assets", key: "AssetsCount"},
      {name: "Users", key: "UsersCount"},
      {name: "Tier", key: "tier"},
      {name: "Quota", key: "quota"},
    ],
    []
  );

  const userTableHeader = useMemo(
    () => [
      {name: "Name", key: "fullName"},
      {name: "Email", key: "email"},
      {name: "Office Phone Number", key: "phone"},
      {name: "Mobile Phone Number", key: "mobilePhone"},
      {name: "Company Name", key: "companyName"},
      {name: "Last Visited On", key: "lastModified"},
      {name: "API Key", key: "apiKey"},
    ],
    []
  );

  const fetchCompanies = useCallback(
    async (nextToken: string | null = null) => {
      try {
        const response = await fetch(
          "https://api.admin.dev.fleet.lynx.carrier.io/entities",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              entityType: "TENANT",
              filters: {
                businessUnit: selectedOption,
              },
              pagination: {
                limit: 30,
                nextToken,
              },
            }),
          }
        );
        const data = await response.json();

        const companyData = data.items.map(
          ({
            parentId,
            contactCountry,
            modifiedOn,
            lastModified,
            tier,
            ...rest
          }: {
            parentId: string;
            contactCountry: string;
            tier: string;
            modifiedOn: string;
            lastModified: string;
          }) => ({
            tier,
            hasParent: parentId ? <CheckIcon /> : <CloseIcon />,
            quota: <GetQuota tier={tier} />,
            country: contactCountry,
            lastModified: formatTimestamp(modifiedOn || lastModified),
            ...rest,
          })
        );

        setCompanies((prevCompanies) => [...prevCompanies, ...companyData]);
        setCompanieCount(data.count);
        setNextCompanyToken(data.nextToken);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    },
    [selectedOption]
  );

  const fetchUsers = useCallback(
    async (nextToken: string | null = null) => {
      try {
        const response = await fetch(
          "https://api.admin.dev.fleet.lynx.carrier.io/entities",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              entityType: "USER",
              filters: {
                businessUnit: selectedOption,
              },
              pagination: {
                limit: 20,
                nextToken,
              },
            }),
          }
        );
        const data = await response.json();

        const usersData = data.items.map(
          ({email, lastModified, ...rest}: {email: string; lastModified: string}) => ({
            email,
            apiKey: <MaskedApiKey email={email} />,
            lastModified: formatTimestamp(lastModified),
            ...rest,
          })
        );

        setUsers((prevUsers) => [...prevUsers, ...usersData]);
        setUserCount(data.count);
        setNextUserToken(data.nextToken);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    [selectedOption]
  );

  useEffect(() => {
    fetchCompanies();
    fetchUsers();
  }, [fetchCompanies, fetchUsers, selectedOption]);

  useEffect(() => {
    setCompanieCount(0);
    setUserCount(0);
    setCompanies([]);
    setUsers([]);
    setNextCompanyToken(null);
    setNextUserToken(null);
  }, [selectedOption]);

  const fetchMoreCompanies = useCallback(async () => {
    if (nextCompanyToken) {
      setIsLoading(true);
      await fetchCompanies(nextCompanyToken);
      setIsLoading(false);
    }
  }, [fetchCompanies, nextCompanyToken]);

  const fetchMoreUsers = useCallback(async () => {
    if (nextUserToken) {
      setIsLoading(true);
      await fetchUsers(nextUserToken);
      setIsLoading(false);
    }
  }, [fetchUsers, nextUserToken]);

  return (
    <>
      <Box className={styles.companyManagement}>
        <Box
          className={styles.headerBox}
          sx={{display: "flex", justifyContent: "space-between"}}
        >
          <Box sx={{fontWeight: "bold"}}>Company Management</Box>
          <InputBase
            placeholder="Search..."
            sx={{
              borderRadius: "4px",
              padding: "4px 8px 4px 48px",
              ml: 2,
              backgroundColor: "#f0f0f0",
            }}
            startAdornment={
              <Search
                sx={{
                  position: "absolute",
                  left: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "action.active",
                }}
              />
            }
          />
        </Box>

        <Box className={styles.groups}>
          <Box className={styles.tabs}>
            <Box
              className={
                selectedTab === "Companies" ? styles.activeTab : styles.passiveTab
              }
              onClick={() => setSelectedTab("Companies")}
            >
              Companies ({companieCount})
            </Box>
            <Divider orientation="vertical" flexItem sx={{mx: 5, height: 75}} />
            <Box
              className={selectedTab === "Users" ? styles.activeTab : styles.passiveTab}
              onClick={() => setSelectedTab("Users")}
            >
              Users ({userCount})
            </Box>
          </Box>
          <Divider sx={{width: "100%"}} />
        </Box>

        <Box className={styles.tableBox}>
          {selectedTab === "Companies" ? (
            <InfiniteScroll
              dataLength={companies.length}
              next={fetchMoreCompanies}
              hasMore={!!nextCompanyToken}
              loader={isLoading ? <LinearProgress /> : null}
              endMessage={
                <p style={{textAlign: "center"}}>
                  <b>No more companies to show</b>
                </p>
              }
            >
              <TableComponent headers={companyTableHeader} data={companies} />
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={users.length}
              next={fetchMoreUsers}
              hasMore={!!nextUserToken}
              loader={isLoading ? <LinearProgress /> : null}
              endMessage={
                <p style={{textAlign: "center"}}>
                  <b>No more users to show</b>
                </p>
              }
            >
              <TableComponent headers={userTableHeader} data={users} />
            </InfiniteScroll>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CompanyManagement;

const MaskedApiKey: React.FC<{email: string}> = ({email}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const toggleVisibility = async () => {
    if (!isVisible && !apiKey) {
      // Fetch API key only if not visible and not already fetched
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.admin.dev.fleet.lynx.carrier.io/api-key?email=${email}`
        );
        const data = await response.json();
        if (response.ok) {
          setApiKey(data.count ? data.items[0].apiKey : "N/A");
        } else {
          setApiKey("Error fetching key");
        }
      } catch (error) {
        console.error("Error fetching API key:", error);
        setApiKey("Error fetching key");
      } finally {
        setLoading(false);
      }
    }
    setIsVisible(!isVisible);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography variant="body1">
        {isVisible ? loading ? <CircularProgress size={20} /> : apiKey : "**********"}
      </Typography>
      <IconButton onClick={toggleVisibility} disabled={loading}>
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </Box>
  );
};

const GetQuota: React.FC<{tier: string}> = ({tier}) => {
  const [quotaMapper, setQuotaMapper] = useState([]);
  const [defaultQuota, setDefaultQuota] = useState("");
  const {selectedOption} = useContext(BusinessUnitContext);

  const fetchUsagePlans = useCallback(async () => {
    try {
      const response = await fetch(
        `https://api.admin.dev.fleet.lynx.carrier.io/usage-plans${selectedOption ? `?platform=${selectedOption}` : ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      const quotas = data.map((item: any) => ({
        type: item.type,
        defaultQuota: item.quota.limit,
      }));
      setQuotaMapper(quotas);
    } catch (error) {
      console.error("Error fetching usage plans:", error);
    }
  }, [selectedOption]);

  useEffect(() => {
    fetchUsagePlans();
  }, [fetchUsagePlans, selectedOption]);

  useEffect(() => {
    const quota: any = quotaMapper.find((x: any) => x.type === tier);
    if (quota) {
      setDefaultQuota(quota.defaultQuota);
    }
  }, [tier, quotaMapper]);

  return (
    <Box>
      {defaultQuota}
      {defaultQuota && " / "}
      {defaultQuota && (
        <span>
          <Button variant="outlined">Live Quota</Button>
        </span>
      )}
    </Box>
  );
};
