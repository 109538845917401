import React, {useContext, useState} from "react";
import {Box, Button, Menu} from "@mui/material";

import MenuItem from "@carrier-io/air-react/MenuItem";

import {UserSettings} from "./UserSettings";

import "../styles/AdminPage.css";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {BusinessUnitContext} from "../context/BusinessUnitContext";

export const HeaderRight = (): JSX.Element => {
  const {selectedOption, setSelectedOption} = useContext(BusinessUnitContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedOption(value);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const email = localStorage.getItem("email") || 'dev.celsius.admin@carrier.com'

  return (
    <>
      <Box className="app-bar-right__settings">
        Select Business Unit:
        <Button
          onClick={handleClick}
          sx={{
            color: "white",
          }}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        >
          {selectedOption}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleMenuItemClick("TTI")}>TTI</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("LFC")}>LFC</MenuItem>
        </Menu>
        <UserSettings fullName="Dev Admin" email={email} />
      </Box>
    </>
  );
};

export default HeaderRight;
