import React, {useEffect, useState, useMemo, useCallback, useContext} from "react";
import {
  Box,
  Button,
  Input,
  InputBase,
} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp, Search} from "@mui/icons-material";
import styles from "../styles/CompanyManagement.module.css";
import TableComponent from "./Table";
import InfiniteScroll from "react-infinite-scroll-component";
import {LinearProgress} from "@mui/material";
import {BusinessUnitContext} from "../context/BusinessUnitContext";
// import AdminPage from "./AdminPage";

interface UsagePlan {
  name: string;
  description: string;
}

const UsagePlanManagement: React.FC = () => {
    const {selectedOption} = useContext(BusinessUnitContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [usagePlans, setUsagePlans] = useState<UsagePlan[]>([]);
  const [usagePlansCount, setUsagePlansCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [nextUsagePlanToken, setNextUsagePlanToken] = useState<string | null>(null);

  const usagePlanTableHeader = useMemo(
    () => [
        {name: "Usage Plan Name", key: "name"},
        {name: "Description", key: "description"},
        {name:"Platform", key: "platform"},
        {name:"Tier", key:"type"},
        {name: "Throttle Burst Limit", key: "throttle.burstLimit"},
        {name:"THrottle Rate Limit", key:"throttle.rateLimit"},
        {name:"Quota Limit", key:"quota.limit"},
        {name:"Quota Period", key:"quota.period"},
    ],
    []
  );

  const fetchUsagePlans = useCallback(
    async (nextToken: string | null = null) => {
      try {
        const response = await fetch(
          `https://api.admin.dev.fleet.lynx.carrier.io/usage-plans${selectedOption?`?platform=${selectedOption}`:''}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        setUsagePlans((prevUsagePlans) => [...prevUsagePlans, ...data]);
        setUsagePlansCount(data.count);
        setNextUsagePlanToken(data.nextToken);
      } catch (error) {
        console.error("Error fetching usage plans:", error);
      }
    },
    [selectedOption]
  );

  useEffect(() => {
    fetchUsagePlans();
  }, [fetchUsagePlans]);
  useEffect(() => {
    setUsagePlans([]);
    setNextUsagePlanToken(null);
  }, [selectedOption]);

  const fetchMoreUsagePlans = useCallback(async () => {
    if (nextUsagePlanToken) {
      setIsLoading(true);
      await fetchUsagePlans(nextUsagePlanToken);
      setIsLoading(false);
    }
  }, [fetchUsagePlans, nextUsagePlanToken]);

  return (
    <>
      {/* <AdminPage /> */}
      <Box className={styles.usagePlanManagement}>
        <Box className={styles.headerBox} sx={{display:"flex", justifyContent:"space-between"}}><Box sx={{fontWeight:"bold"}}>Usage Plans Management</Box>
          <InputBase
            placeholder="Search..."
            sx={{
              borderRadius: "4px",
              padding: "4px 8px 4px 48px",
              ml: 2,
              left:"400px",
              backgroundColor: "#f0f0f0",
            }}
            startAdornment={
              <Search
                sx={{
                  position: "absolute",
                  left: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "action.active",
                }}
              />
            }
          />
          &nbsp;
          <Button variant="contained" color="primary"> Add Usage Plan</Button>
        </Box>
        <Box className={styles.tableBox}>
        <InfiniteScroll
              dataLength={usagePlans.length}
              next={fetchMoreUsagePlans}
              hasMore={!!nextUsagePlanToken}
              loader={isLoading ? <LinearProgress /> : null}
              endMessage={
                <p style={{textAlign: "center"}}>
                  <b>No more Usage Plans to show</b>
                </p>
              }
            >
              <TableComponent headers={usagePlanTableHeader} data={usagePlans} />
            </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
};

export default UsagePlanManagement;
