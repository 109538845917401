import React, {useEffect, useState} from "react";
import {List, ListItem, ListItemIcon} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import overviewIcon from "../assets/overview.svg";
import companyMgmtIcon from "../assets/company-managment.svg";
import productMgmtIcon from "../assets/product-management.svg";
import usagePlanMgmtIcon from "../assets/usage-plan-management.svg";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null);

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    {
      text: "Overview",
      path: "/overview",
      icon: (
        <img
          src={overviewIcon}
          alt="Overview"
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            filter:
              activeItem === "/overview"
                ? "invert(43%) sepia(92%) saturate(1849%) hue-rotate(194deg) brightness(102%) contrast(101%)"
                : undefined,
          }}
          data-tip="Overview"
        />
      ),
    },
    {
      text: "Company Management",
      path: "/company-management",
      icon: (
        <img
          src={companyMgmtIcon}
          alt="Company Management"
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            filter:
              activeItem === "/company-management"
                ? "invert(43%) sepia(92%) saturate(1849%) hue-rotate(194deg) brightness(102%) contrast(101%)"
                : undefined,
          }}
          data-tip="Company Management"
        />
      ),
    },
    {
      text: "Products Management",
      path: "/products-management",
      icon: (
        <img
          src={productMgmtIcon}
          alt="Products Management"
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            filter:
              activeItem === "/products-management"
                ? "invert(43%) sepia(92%) saturate(1849%) hue-rotate(194deg) brightness(102%) contrast(101%)"
                : undefined,
          }}
          data-tip="Products Management"
        />
      ),
    },
    {
      text: "Usage Plans Management",
      path: "/usage-plans-management",
      icon: (
        <img
          src={usagePlanMgmtIcon}
          alt="Usage Plans Management"
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            filter:
              activeItem === "/usage-plans-management"
                ? "invert(43%) sepia(92%) saturate(1849%) hue-rotate(194deg) brightness(102%) contrast(101%)"
                : undefined,
          }}
          data-tip="Usage Plans Management"
        />
      ),
    },
  ];

  const handleItemClick = (path: string) => {
    setActiveItem(path);
    navigate(path);
  };

  return (
    <>
      <List>
        {menuItems.map(({text, path, icon}) => (
          <ListItem
            key={text}
            onClick={() => handleItemClick(path)}
            sx={{paddingRight: 0}}
          >
            <ListItemIcon>{icon}</ListItemIcon>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default NavBar;

