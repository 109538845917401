import React from "react";
import {Route, Routes} from "react-router-dom";

import LoginPage from "./components/LoginPage";
import CompanyManagement from "./components/CompanyManagement";
import OverviewPage from "./components/OverviewPage";
import UsagePlanManagement from "./components/usagePlanManagement";
import ProductManagement from "./components/ProductManagement";

const AppRoutes: React.FC = () => {
  return (

      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/overview" element={<OverviewPage />} />
        {/* <Route path="/admin" element={<AdminPage />} /> */}
        <Route path="/company-management" element={<CompanyManagement />} />
        <Route path="/usage-plans-management" element={<UsagePlanManagement/>}/>
        <Route path="/products-management" element={<ProductManagement/>}/>
      </Routes>
  );
};

export default AppRoutes;
