import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

import theme from "./theme";
import styles from "./styles/App.module.css";
import MainLayout from "./components/MainLayout";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box className={styles.body} sx={{ overflow: "hidden" }}>
          <MainLayout />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
