import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import NavBar from "./NavBar";
import AppRoutes from "../routes";
import { BusinessUnitProvider } from "../context/BusinessUnitContext";
import { useLocation } from "react-router-dom";

const MainLayout: React.FC = () => {
  const location = useLocation();
  const isLoginOrDefaultRoute = location.pathname === "/login" || location.pathname === "/";

  return (
    <BusinessUnitProvider>
      <Box>
        {!isLoginOrDefaultRoute && <Header />}
      </Box>
      <Box sx={{ display: "flex" }}>
        {!isLoginOrDefaultRoute && <NavBar />}
        <AppRoutes />
      </Box>
    </BusinessUnitProvider>
  );
};

export default MainLayout;
