import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, InputBase } from "@mui/material";
import styles from "../styles/CompanyManagement.module.css";
import TableComponent from "./Table";
import { Search } from '@mui/icons-material';
import { BusinessUnitContext } from "../context/BusinessUnitContext";

interface ProductFormData {
  productName: string;
  description: string;
  baseUrl: string;
  platform: string;
  yaml: File | null;
}

interface Product {
    productId: string;  
    productName: string;
    description: string;
    baseUrl: string;
    platform: string;
    isActive: boolean;
    createdOn: string;
    createdBy: string;
    yaml: File | null;
  }

const initialFormData: ProductFormData = {
  productName: '',
  description: '',
  baseUrl: '',
  platform: '',
  yaml: null,
};



const ProductManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<ProductFormData>(initialFormData);
  const [message, setMessage] = useState<string | null>(null); 
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const {selectedOption} = useContext(BusinessUnitContext);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const { name } = e.target;
      setFormData((prev) => ({ ...prev, [name]: e.target.files![0] }));
    }
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const userEmail = localStorage.getItem("email") || '';
    
    const form = new FormData();
    form.append('productName', formData.productName);
    form.append('description', formData.description);
    form.append('baseUrl', formData.baseUrl);
    form.append('platform', formData.platform);
    form.append('createdBy', userEmail);

  
    if (formData.yaml) {
      form.append('yaml', formData.yaml);
    }

    
    try {
      const response = await fetch('https://api.admin.dev.fleet.lynx.carrier.io/products', {
        method: 'POST',
        body: form,
      });
      console.log("response :: ", response)
      if (response.ok) {
        setMessage('Product created successfully');
        setMessageType('success');
        setFormData(initialFormData);
        await fetchProducts();
      } else {
        setMessage('Error creating product');
        setMessageType('error');
      }
    } catch (error) {
      setMessage('Error creating product');
      setMessageType('error');
    }
  };

const fetchProducts = async () => {
        try {
          const response = await fetch('https://api.admin.dev.fleet.lynx.carrier.io/products');
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
    
          const data: Product[] = await response.json();
          const mappedData: any = data.filter(item => item.isActive && item.platform.toUpperCase() === selectedOption.toUpperCase()).map(({ isActive, ...rest }) => ({
                                  isActive: isActive ? "true" : "false", ...rest}));
          setProducts(mappedData);
        } catch (error) {
            console.log("error :: ",error)
        }
      };
    
      useEffect(() => {
        fetchProducts();
      }, [selectedOption]);

      const productTableHeader = [
        {name: "Product ID", key: "productId"},
        {name: "Product Name", key: "productName"},
        {name: "Description", key: "description"},
        {name: "Active", key: "isActive"},
        {name: "Platform", key: "platform"},
        {name: "BaseUrl", key: "baseUrl"},
        {name: "Created By", key: "createdBy"},
        {name: "Created On", key: "createdOn"},
        {name: "Modified By", key: "modifiedBy"},
        {name: "Modified On", key: "modifiedOn"},
      ];

  return (
    <>
    <style>
        {`
          input:focus, textarea:focus {
            outline: none;
            border-color: '#3B82F6';
            box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
          }

          button:hover {
            background-color: '#374151';
          }
        `}
      </style>
      <Box className={styles.companyManagement}>
      <Box className={styles.headerBox} sx={{display:"flex", justifyContent:"space-between"}}>
        <Box sx={{fontWeight:"bold"}}>Product Management</Box>
          <InputBase
            placeholder="Search..."
            sx={{
              borderRadius: "4px",
              padding: "4px 8px 4px 48px",
              ml: 2,
              left:"600px",
              backgroundColor: "#f0f0f0",
            }}
            startAdornment={
              <Search
                sx={{
                  position: "absolute",
                  left: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "action.active",
                }}
              />
            }
          />
          &nbsp;
          <Button variant="contained" color="primary" onClick={()=> setIsOpen(true)}> Add Product</Button>
        </Box>

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '16px',
              width: '70%',
              maxWidth: '600px',
              maxHeight: '80vh',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <button
              onClick={() => {
                setIsOpen(false);
                setFormData(initialFormData);
                setMessage(null); // Added
              }}
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                padding: '2px',
              }}
            >
              ✖️
            </button>

            <h1 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '24px' }}>Add Product</h1>

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    name="productName"
                    value={formData.productName}
                    onChange={handleInputChange}
                    placeholder="Product Name"
                    style={{
                      width: '100%',
                      padding: '8px',
                      paddingLeft: '32px',
                      border: '1px solid #ccc',
                      borderRadius: '6px',
                      fontSize: '14px',
                      height: '36px',
                    }}
                  />
                  <span style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', color: '#666' }}>
                    🏷️
                  </span>
                </div>

                <div style={{ position: 'relative' }}>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                    style={{
                      width: '100%',
                      padding: '8px',
                      paddingLeft: '32px',
                      border: '1px solid #ccc',
                      borderRadius: '6px',
                      minHeight: '40px',
                      fontSize: '14px',
                      resize: 'vertical',
                    }}
                  />
                  <span style={{ position: 'absolute', left: '8px', top: '12px', color: '#666' }}>
                    📝
                  </span>
                </div>

                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    name="baseUrl"
                    value={formData.baseUrl}
                    onChange={handleInputChange}
                    placeholder="Base URL"
                    style={{
                      width: '100%',
                      padding: '8px',
                      paddingLeft: '32px',
                      border: '1px solid #ccc',
                      borderRadius: '6px',
                      fontSize: '14px',
                      height: '36px',
                    }}
                  />
                  <span style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', color: '#666' }}>
                    🔗
                  </span>
                </div>

                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    name="platform"
                    value={formData.platform}
                    onChange={handleInputChange}
                    placeholder="Platform"
                    style={{
                      width: '100%',
                      padding: '4px 4px 4px 16px',
                      border: '1px solid #ccc',
                      borderRadius: '6px',
                      fontSize: '14px',
                      height: '36px',
                    }}
                  />
                </div>

                <div style={{ position: 'relative' }}>
                  {formData.yaml ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '8px',
                        border: '1px solid #ccc',
                        borderRadius: '6px',
                        backgroundColor: '#f9f9f9',
                        fontSize: '14px',
                      }}
                    >
                      <span style={{ color: '#666' }}>📄</span>
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {formData.yaml.name}
                      </span>
                      <button
                        onClick={() => setFormData((prev) => ({ ...prev, yaml: null }))}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '14px',
                          color: '#FF0000',
                        }}
                      >
                       X
                      </button>
                    </div>
                  ) : (
                  <input
                    type="file"
                    id="file-upload"
                    name="yaml"
                    multiple
                    onChange={handleFileChange}
                    accept=".yaml,.yml"
                    style={{ justifyContent: 'center' }}
                    />
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                {message && (
                  <div
                  className="p-4 rounded-md"
                  style={{
                    textAlign: 'center',
                    marginBottom: '16px',
                    color: messageType === 'success' ? '#16A34A' : '#B91C1C'
                  }}
                  >
                    {message}
                  </div>
                )}
                    </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    style={{
                        backgroundColor: '#4B5563',
                        color: 'white',
                        padding: '12px 24px',
                        borderRadius: '6px',
                        fontSize: '14px',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
              </div>
         </form>
          </div>
        </div>
      )}
     
        <Box className={styles.tableBox}>
         <TableComponent headers={productTableHeader} data={products} />
        </Box>
    </Box>
    </>
  );
};

export default ProductManagement;
