import React, {memo} from "react";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import styles from "../styles/Table.module.css";

interface TableProps {
  headers: {name: string; key: string}[];
  data: any[];
}

const getNestedValue = (object:any, key:any) => {
  // Split the key into parts based on dot notation
  const keys = key.split('.');

  // Use reduce to traverse the object and get the value
  return keys.reduce((acc:any, part:any) => {
    return acc && acc[part]; // Traverse the object by each part of the key
  }, object);
};

const TableComponent: React.FC<TableProps> = memo(({headers, data}) => {
  return (
    <Box className={styles["table-container"]}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.key} className={styles.tabledHeaderCell}>
                <span className={styles.tabledHeaderCellText}>{header.name}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, index) => (
            <TableRow key={index} className={styles.tableRow}>
              {headers.map((header) => (
                <TableCell key={`${index}-${header.key}`}>
                  {getNestedValue(rowData, header.key)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
});

export default TableComponent;
