import Copyright from "@carrier-io/air-react/patterns/Copyright/Copyright";
import Box from "@carrier-io/air-react/Box";
import Typography from "@carrier-io/air-react/Typography";
import SignIn from "@carrier-io/air-react/patterns/SignIn/SignIn";
import {SignInFooter} from "@carrier-io/air-react/patterns/SignIn/SignInFooter";
import Logo from "@carrier-io/air-react/patterns/Logo/Logo";
import {useLocation} from "react-router-dom";

import CarrierLogoUrl from "../assets/carrier-logo.png";
import {LoginForm} from "./LoginForm";
import {ForgotPasswordForm} from "./ForgotPasswordForm";
import {LoginLynxLogo} from "./LoginLynxLogo";
import {TermsOfServiceAndPrivacyPolicyOptimized} from "./TermsOfServiceAndPrivacyPolicyOptimized";
import {TermsOfServiceAndPrivacyPolicy} from "./TermsOfServiceAndPrivacyPolicy";
import {REACT_APP_FEATURE_ONE_TRUST} from "../constants/policy";

export const LoginPage = (): JSX.Element => {
  const {state} = useLocation();
  const isOneTrust = REACT_APP_FEATURE_ONE_TRUST;
  const boxStyle = {
    gridColumnGap: "11px",
  };

  return (
    <Box height="100vh" marginLeft="40%">
      <SignIn
        footer={
          <SignInFooter
            left={
              isOneTrust ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TermsOfServiceAndPrivacyPolicyOptimized labelEntry="Terms of Service" />
                  <TermsOfServiceAndPrivacyPolicyOptimized labelEntry="Privacy Policy" />
                </Box>
              ) : (
                <TermsOfServiceAndPrivacyPolicy labelEntry="Terms of Service & Privacy Policy" />
              )
            }
            middle={
              <Logo
                alt="auth.carriers-logo"
                href="https://www.carrier.com/"
                src={CarrierLogoUrl}
              />
            }
            right={<Copyright text="Carrier" />}
            sx={boxStyle}
          />
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <LoginLynxLogo />
          <Typography
            sx={{
              marginTop: "30px",
              marginBottom: "10px",
            }}
            variant="h5"
          >
            Welcome to LYNX Fleet
          </Typography>
          <Typography
            color="black"
            sx={{
              marginBottom: "24px",
            }}
            variant="body2"
          >
            Login with your username and password
          </Typography>
          <LoginForm userSpecifiedPath={state?.userSpecifiedPath || "/"} />
          <Box
            sx={{
              marginTop: "12px",
            }}
          >
            <ForgotPasswordForm />
          </Box>
        </Box>
      </SignIn>
    </Box>
  );
};

export default LoginPage;
