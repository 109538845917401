import Container from '@carrier-io/air-react/Container';
import TextField, { TextFieldInputProps } from '@carrier-io/air-react/TextField';
import Box from '@carrier-io/air-react/Box';
import Button from '@carrier-io/air-react/Button';
import { ChangeEvent, useState } from 'react';
import IconButton from '@carrier-io/air-react/IconButton';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';


import {ForgotPasswordForm} from './ForgotPasswordForm'
import { Dialog } from './Dialog';

export interface ChangePasswordDialogViewProps {
  handleClose: () => void;
}

export const ChangePasswordDialogView = ({
  handleClose,
}: ChangePasswordDialogViewProps): JSX.Element => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const handleCurrentPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target.value);
  };

  const handleRepeatNewPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRepeatNewPassword(event.target.value);
  };

  const [showOldPassword, setShowOldPassword] = useState<boolean>(true);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(true);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState<boolean>(true);

  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [repeatNewPasswordError, setRepeatNewPasswordError] = useState('');

  const [oldPasswordChecked, setOldPasswordChecked] = useState(false);
  const [newPasswordChecked, setNewPasswordChecked] = useState(false);
  const [repeatNewPasswordChecked, setRepeatNewPasswordChecked] = useState(false);

  const changeCurrentPasswordVisible = (): void => {
    setShowOldPassword((prevValue) => !prevValue);
  };

  const changeNewPasswordVisible = (): void => {
    setShowNewPassword((prevValue) => !prevValue);
  };

  const changeRepeatNewPasswordVisible = (): void => {
    setShowRepeatNewPassword((prevValue) => !prevValue);
  };

  const inputCurrentPassword: TextFieldInputProps = {
    endAdornment: oldPasswordChecked ? (
      <Check fontSize="small" />
    ) : (
      <IconButton onClick={changeCurrentPasswordVisible}>
        {showOldPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ),
    type: showOldPassword ? 'password' : 'text',
  };

  const inputNewPassword: TextFieldInputProps = {
    endAdornment: newPasswordChecked ? (
      <Check fontSize="small" />
    ) : (
      <IconButton onClick={changeNewPasswordVisible}>
        {showNewPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ),
    type: showNewPassword ? 'password' : 'text',
  };

  const inputRepeatNewPassword: TextFieldInputProps = {
    endAdornment: repeatNewPasswordChecked ? (
      <Check fontSize="small" />
    ) : (
      <IconButton onClick={changeRepeatNewPasswordVisible}>
        {showRepeatNewPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    ),
    type: showRepeatNewPassword ? 'password' : 'text',
  };

  const validate = (): void => {}

//   const validate = (): void => {
//     const formIsValid = validateChangePasswordForm({
//       oldPassword,
//       newPassword,
//       repeatNewPassword,
//       setOldPasswordError,
//       setNewPasswordError,
//       setRepeatNewPasswordError,
//       setOldPasswordChecked,
//       setNewPasswordChecked,
//       setRepeatNewPasswordChecked,
//       t,
//     });

//     if (formIsValid) {
//       handleSubmit({ oldPassword, newPassword, handleClose })
//         // eslint-disable-next-line no-console
//         .catch((error) => console.error('handleChangePassword:', error));
//     }
//   };

  return (
    <Dialog
      open
      onClose={handleClose}
      dialogTitle='Change password'
      maxWidth="md"
      content={
        <Container
          sx={{
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
          }}
        >
          <Box style={{ marginBottom: '16px' }} display="flex" flexDirection="column">
            {/* do not remove dummyTextInput because it helps to prevent autocomplete for email  */}
            <TextField
              name="dummyTextInput"
              autoComplete="new-test"
              id="dummyTextInput"
              sx={{
                '& .MuiFilledInput-root': {
                  height: '0px',
                  backgroundColor: '#FFFFFF',
                  minWidth: '0px',
                  width: '0px',
                },
              }}
              value=""
            />
            <TextField
              name="oldPassword"
              autoComplete="new-password"
              id="oldPassword"
              inputSetting={inputCurrentPassword}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: '#FFFF',
                  minWidth: '52px',
                  width: '552px',
                  background: 'rgba(0, 0, 0, 0.06)'
                },
              }}
              color="primary"
              label='Enter current password'
              showBorder
              value={oldPassword}
              onChange={handleCurrentPasswordChange}
              error={Boolean(oldPasswordError)}
              helperText={oldPasswordError}
            />
          </Box>
          <ForgotPasswordForm hideCancelButton renamedSignInButtonTitle="common.cancel" />
          <Box sx={{ marginTop: '16px', marginBottom: '16px' }}>
            <TextField
              id="newPassword"
              inputSetting={inputNewPassword}
              sx={{ minWidth: '52px', width: '552px', backgroundColor: '#FFFF' }}
              color="primary"
              label='Enter new password'
              showBorder={false}
              value={newPassword}
              onChange={handleNewPasswordChange}
              error={Boolean(newPasswordError)}
              helperText={newPasswordError}
            />
          </Box>
          <Box>
            <TextField
              id="repeatNewPassword"
              inputSetting={inputRepeatNewPassword}
              sx={{ minWidth: '52px', width: '552px', backgroundColor: '#FFFF' }}
              color="primary"
              label='Repeat new password'
              showBorder={false}
              value={repeatNewPassword}
              onChange={handleRepeatNewPasswordChange}
              error={Boolean(repeatNewPasswordError)}
              helperText={repeatNewPasswordError}
            />
          </Box>
          <Box style={{ marginTop: '16px' }}>
            <Typography>Password requirements:</Typography>
            <ul style={{ paddingLeft: '20px', margin: 0 }}>
              <li>
                <Typography>At least 8 characters</Typography>
              </li>
              <li>
                <Typography>A lowercase letter</Typography>
              </li>
              <li>
                <Typography>An uppercase letter</Typography>
              </li>
              <li>
                <Typography>A number</Typography>
              </li>
            </ul>
          </Box>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}
          >
            <Button color="secondary" variant="outlined" onClick={handleClose}>
             Cancel
            </Button>
            {false ? (
              <CircularProgress size={30} sx={{ marginLeft: '10px' }} />
            ) : (
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  ml: 1,
                }}
                onClick={validate}
              >
              Submit
              </Button>
            )}
          </Box>
        </Container>
      }
    />
  );
};
