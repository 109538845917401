import { memo } from "react";
import { Container } from "@mui/material";
import AppBarTopHeader from "@carrier-io/air-react/patterns/AppBarTopHeader";

import { HeaderRight } from "./HeaderRight";
import { HeaderLeft } from "./HeaderLeft";

import "../styles/AdminPage.css";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <Container className="header">
      <AppBarTopHeader
        appBarProps={{
          sx: {
            boxShadow: "none",
            zIndex: 13000,
            backgroundColor: "rgb(0, 22, 101)",
          },
        }}
        toolbarProps={{
          disableGutters: true,
          variant: "dense",
          sx: {
            backgroundColor: "rgb(0, 22, 101)",
          },
        }}
        leftJustifiedGridProps={{ className: "app-bar-left" }}
        rightJustifiedGridProps={{ className: "app-bar-right" }}
        leftJustifiedComponent={<HeaderLeft />}
        rightJustifiedComponent={<HeaderRight />}
      />
    </Container>
  );
};

export default memo(Header);
